import 'owl.carousel'
import { CountUp } from 'countup.js';
import "magnific-popup";
// import AOS from 'aos';

// JavaScript
$(document).ready( function () {
  // header
  $('.menu-link').click(function(e) {
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $(window).on('scroll', function () {
    // transparent header
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  });

  var countUp_sponsor = new CountUp('sponsor', 168984, 3000, { enableScrollSpy: true });
  countUp_sponsor.start();
  var countUp_students = new CountUp('students', 214762, 3000, { enableScrollSpy: true });
  countUp_students.start();
  var countUp_days = new CountUp('days', 5923, 3000, { enableScrollSpy: true });
  countUp_days.start();


  // scroll top
  $('#backTop').click(function(){
    $("html,body").animate({scrollTop: 0}, 600);
  });

  // video
  $('.popup-video').magnificPopup({
    type: 'iframe'
  });
});
